// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tableWrapper__rCJom {
  margin: 0;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  width: 100%;
}
.styles_tableWrapper__rCJom:nth-child(even) {
  background-color: lightgray;
}

td, th {
  padding: 10px 30px;
  line-height: 42px;
}
td p, th p {
  margin: 0;
}

.styles_listWrapper__K896C {
  position: relative;
}

.styles_wrapTable__Tn1uF {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
}

.styles_tableHeader__y8Re9 {
  font-weight: 700;
  background-color: rgb(181, 181, 181) !important;
}

.styles_wrapper__02UUx {
  padding: 20px 40px;
  font-family: "Poppins", sans-serif;
}

.styles_listHeader__Bvu0R {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_buttonAndHeader__YmgVa {
  display: flex;
  gap: 20px;
  align-items: center;
}
.styles_buttonAndHeader__YmgVa h3 {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/components/ListRowsTable/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,WAAA;AAAF;AAEE;EACE,2BAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,iBAAA;AADF;AAGE;EACE,SAAA;AADJ;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,WAAA;EAEA,cAAA;EACA,mBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,+CAAA;AAHF;;AAMA;EACE,kBAAA;EACA,kCAAA;AAHF;;AAMA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAHF;AAKE;EACE,SAAA;EACA,UAAA;AAHJ","sourcesContent":[".tableWrapper {\n  margin: 0;\n  padding: 10px;\n  text-align: center;\n  white-space: nowrap;\n  align-items: center;\n\n  width: 100%;\n\n  &:nth-child(even) {\n    background-color: lightgray;\n  }\n}\n\ntd, th {\n  padding: 10px 30px;\n  line-height: 42px;\n\n  p {\n    margin: 0;\n  }\n}\n\n.listWrapper {\n  position: relative;\n}\n\n.wrapTable {\n  width: 100%;\n  // position: absolute;\n  overflow: auto;\n  white-space: nowrap;\n}\n\n.tableHeader {\n  font-weight: 700;\n  background-color: rgb(181, 181, 181) !important;\n}\n\n.wrapper {\n  padding: 20px 40px;\n  font-family: 'Poppins', sans-serif;\n}\n\n.listHeader {\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.buttonAndHeader {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n\n  h3 {\n    margin: 0;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `styles_tableWrapper__rCJom`,
	"listWrapper": `styles_listWrapper__K896C`,
	"wrapTable": `styles_wrapTable__Tn1uF`,
	"tableHeader": `styles_tableHeader__y8Re9`,
	"wrapper": `styles_wrapper__02UUx`,
	"listHeader": `styles_listHeader__Bvu0R`,
	"buttonAndHeader": `styles_buttonAndHeader__YmgVa`
};
export default ___CSS_LOADER_EXPORT___;
