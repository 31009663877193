// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_list__bwkHd {
  list-style: none;
  width: 1000px;
}
.styles_list__bwkHd li {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/components/ListRowFieldAssignments/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,kCAAA;AACJ","sourcesContent":[".list {\n  list-style: none;\n  width: 1000px;\n\n  li {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `styles_list__bwkHd`
};
export default ___CSS_LOADER_EXPORT___;
