import { Field, Form, Formik } from "formik";
import styles from "./styles.module.scss";
import DranNDropUpload from "../../Common/DragNDropUpload";
import { ICreateListValues } from "../../../types/UploadCenter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadCenterService } from "../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../common/queryKeys";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ListRowsTable from "../components/ListRowsTable";
import ListRowFieldAssignments from "../components/ListRowFieldAssignments";
import uploadListValidationSchema from "./validation";
import { listOfRequiredFieldsSavingListRow } from "../../../common/constants";

const UploadList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [stage, setStage] = useState(0);

  const { mutateAsync: createList } = useMutation({
    mutationFn: uploadCenterService.createList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const submitAddingList = async (values: ICreateListValues) => {
    if (!values.dbFieldsAssignment) return;

    const valuesFields = Object.values(values.dbFieldsAssignment)
    const isValid = !listOfRequiredFieldsSavingListRow.find(field => !valuesFields.includes(field));

    if (!isValid) return;

    await createList(values);
    navigate("/uploadcenter");
  };

  const nextSubmitFunction = (submitForm: () => void) => () => {
    if (stage !== 2) return setStage((prev) => (prev += 1));
    submitForm();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        <h1 className={styles.mainHeader}>Add new list</h1>
        <Formik
          initialValues={{
            name: "",
            deliverer: undefined,
            file: null,
            dbFieldsAssignment: undefined,
          }}
          onSubmit={submitAddingList}
          validationSchema={uploadListValidationSchema}
          isInitialValid={false}
        >
          {({ values, setFieldValue, submitForm, errors, isValid }) => (
            <Form>
              <div className={styles.fields}>
                {stage === 0 && (
                  <>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className={styles.textInput}
                      placeholder="List name"
                    />
                    <p>{errors.name}</p>
                    <Field
                      id="deliverer"
                      name="deliverer"
                      type="text"
                      className={styles.textInput}
                      placeholder="Deliverer"
                    />
                    <p>{errors.deliverer}</p>
                    <DranNDropUpload
                      name="file"
                      onChangeFunction={(value) => setFieldValue("file", value)}
                      value={values.file}
                    />
                  </>
                )}

                {stage === 1 && (
                  <div className={styles.previewTable}>
                    <p>Preview file</p>
                    <>{console.log(values.file)}</>

                    <ListRowsTable rows={values.file} />
                  </div>
                )}

                {stage === 2 && (
                  <>
                    <div className={styles.previewTable}>
                      <ListRowFieldAssignments
                        rows={values.file!}
                        setFieldsAssignment={(value: {
                          [key: string]: string;
                        }) => setFieldValue("dbFieldsAssignment", value)}
                      />
                    </div>
                  </>
                )}
              </div>

              <br />

              <Link to="/uploadcenter">
                <button className={styles.addButton} type="button">
                  Cancel
                </button>
              </Link>
              <button
                className={styles.addButton}
                type="button"
                disabled={!isValid}
                onClick={nextSubmitFunction(submitForm)}
              >
                {stage === 2 ? "Submit" : "Next"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UploadList;
