import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { adminSettingsService } from "../../../services/admin_settings";
import { QUERY_KEYS } from "../../../common/queryKeys";
import styles from "./styles.module.scss";
import { Field, Form, Formik } from "formik";

const AdminSettings = () => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getAdminSettings],
    queryFn: adminSettingsService.getAdminSettings,
  });

  const { mutate: updateFunc } = useMutation({
    mutationFn: adminSettingsService.updateAdminSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAdminSettings],
      });
    },
  });

  const addFemale = ({ femaleTitle }, { resetForm }) => {
    const list = [...data.femaleTitles, femaleTitle];
    updateFunc({ femaleTitles: list });
    resetForm();
  };

  const addMale = ({ maleTitle }, { resetForm }) => {
    const list = [...data.maleTitles, maleTitle];
    updateFunc({ maleTitles: list });
    resetForm();
  };

  const deleteFemale = (idx: number) => {
    const list = [...data.femaleTitles];
    list.splice(idx, 1);
    updateFunc({ femaleTitles: list });
  };

  const deleteMale = (idx: number) => {
    const list = [...data.maleTitles];
    list.splice(idx, 1);
    updateFunc({ maleTitles: list });
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.wrapper}>
      <h3>Gender detection settings:</h3>
      <br />
      <div className={styles.listWrapper}>
        <ul className={styles.list}>
          <li>Male titles:</li>
          {data.maleTitles.map((i, idx) => (
            <li>
              <span>{i}</span>
              <button onClick={() => deleteMale(idx)}>delete</button>
            </li>
          ))}
        </ul>

        <ul className={styles.list}>
          <li>Female titles:</li>
          {data.femaleTitles.map((i, idx) => (
            <li>
              <span>{i}</span>
              <button onClick={() => deleteFemale(idx)}>delete</button>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.listWrapper}>
        <Formik initialValues={{ maleTitle: "" }} onSubmit={addMale}>
          <Form>
            <div>
              <Field
                id="maleTitle"
                name="maleTitle"
                type="text"
                placeholder="Add title..."
              />
              <button type="submit">Add</button>
            </div>
          </Form>
        </Formik>

        <Formik initialValues={{ femaleTitle: "" }} onSubmit={addFemale}>
          <Form>
            <div>
              <Field
                id="femaleTitle"
                name="femaleTitle"
                type="text"
                placeholder="Add title..."
              />
              <button type="submit">Add</button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AdminSettings;
