import { listTitles, formatListElementData } from "../../helpers";
import { IList } from "../../../../../types/UploadCenter";
import styles from "./styles.module.scss";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadCenterService } from "../../../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../../../common/queryKeys";
import { Link } from "react-router-dom";

const ListElement = ({
  list,
  listId,
  showActionButtons = false,
}: {
  list: string[];
  listId?: number;
  showActionButtons?: boolean;
}) => {
  const queryClient = useQueryClient();

  const { mutate: deleteFunc } = useMutation({
    mutationFn: uploadCenterService.deleteList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.getAllUploadedLists],
      });
    },
  });

  const deleteList = () => deleteFunc(listId!);

  return (
    <li className={styles.liElement}>
      <ul className={styles.ulProperties}>
        {list.map((i, idx) => (
          <li key={`list-elem-${idx}`}>{i}</li>
        ))}
        {showActionButtons && (
          <li>
            <Link to={`/uploadedlist/${listId}`}>
              <IconButton
                edge="end"
                aria-label="find"
                className={styles.findIcon}
              >
                <SearchRoundedIcon className={styles.icon} />
              </IconButton>
            </Link>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={deleteList}
              className={styles.deleteIcon}
            >
              <DeleteOutlineIcon className={styles.icon} />
            </IconButton>
          </li>
        )}
      </ul>
    </li>
  );
};

const ListOfLists = ({ lists }: { lists: IList[] }) => {
  return (
    <ul className={styles.listOfLists}>
      <ListElement list={listTitles} />
      {lists.map((i, idx) => (
        <ListElement
          listId={i.id}
          key={`list-${idx}-id-${i.id}`}
          list={formatListElementData(i)}
          showActionButtons
        />
      ))}
    </ul>
  );
};

export default ListOfLists;
