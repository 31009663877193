// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dragDrop__XUQjK {
  background-color: #fff;
  border: 2px dashed #fff;
  padding-top: 10px;
  border-radius: 10px;
  text-align: center;
  transition: border-color 0.3s ease-in-out;
}

.styles_dragActive__Wvtva {
  border-color: #8400ff;
  background-color: #e0f7ff;
}

.styles_dragDrop__XUQjK.styles_active__aT2zX {
  border-color: #007bff;
}

.styles_fileInput__vTsU3 {
  display: none;
}

.styles_fields__dAzxC {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.styles_textInput__q9\\+ER {
  padding: 10px;
  font-size: 16px;
}

.styles_iconContainer__AuoSc {
  margin-bottom: 10px;
}

.styles_uploadLabel__GUDWl {
  margin: 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/DragNDropUpload/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yCAAA;AACF;;AAEA;EACE,qBAAA;EACA,yBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,aAAA;AACF","sourcesContent":[".dragDrop {\n  background-color: #fff;\n  border: 2px dashed #fff;\n  padding-top: 10px;\n  border-radius: 10px;\n  text-align: center;\n  transition: border-color 0.3s ease-in-out;\n}\n\n.dragActive {\n  border-color: #8400ff;\n  background-color: #e0f7ff;\n}\n\n.dragDrop.active {\n  border-color: #007bff;\n}\n\n.fileInput {\n  display: none;\n}\n\n.fields {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.textInput {\n  padding: 10px;\n  font-size: 16px;\n}\n\n.iconContainer {\n  margin-bottom: 10px;\n}\n\n.uploadLabel {\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragDrop": `styles_dragDrop__XUQjK`,
	"dragActive": `styles_dragActive__Wvtva`,
	"active": `styles_active__aT2zX`,
	"fileInput": `styles_fileInput__vTsU3`,
	"fields": `styles_fields__dAzxC`,
	"textInput": `styles_textInput__q9+ER`,
	"iconContainer": `styles_iconContainer__AuoSc`,
	"uploadLabel": `styles_uploadLabel__GUDWl`
};
export default ___CSS_LOADER_EXPORT___;
