import { Field, Form, Formik } from "formik";
import { IListRow } from "../../../../types/UploadCenter";
import styles from "./styles.module.scss";
import { listOfRequiredFieldsSavingListRow } from "../../../../common/constants";

const listOfDBFields = [
  "skip",
  "email",
  "gender",
  "firstName",
  "lastName",
  "birthDate",
  "streetAddress",
  "houseNumber",
  "zipCode",
  "city",
  "country",
  "countryPhoneCode",
  "phoneAreaCode",
  "phoneNumber",
  "SOI_Date",
  "SOI_Ip",
  "SOI_UserAgent",
  "DOI_Date",
  "DOI_Ip",
  "DOI_UserAgent",
  "TOI_Date",
  "TOI_Ip",
  "TOI_UserAgent",
  "isBlocked",
  "blockedSince",
  "blockedByUser",
  "blockedReason",
  "notSpecifiedField1",
  "notSpecifiedField2",
  "notSpecifiedField3",
  "notSpecifiedField4",
  "notSpecifiedField5",
  "notSpecifiedField6",
  "notSpecifiedField7",
  "notSpecifiedField8",
  "notSpecifiedField9",
  "notSpecifiedField10",
];

const autoAssignHelper = {
  email: ["mail", "email-adresse"],
  gender: ["gender", "geschlecht", "anrede"],
  firstName: ["first", "vorname"],
  lastName: ["last", "nachname"],
  birthDate: ["birthdate", "geburtsdatum"],
  streetAddress: ["street", "address", "straße"],
  houseNumber: ["housenumber", "hausnr", "hausnummer"],
  zipCode: ["zip", "plz"],
  city: ["city", "stadt"],
  country: ["country", "land"],
  countryPhoneCode: ["phonecode"],
  phoneAreaCode: ["areacode"],
  phoneNumber: ["phonenumber"],
  SOI_Date: ["soi_date", "soi_zeipunkt"],
  SOI_Ip: ["soi_ip", "soi-ip"],
  SOI_UserAgent: [],
  DOI_Date: ["doi_date", "doi_zeipunkt"],
  DOI_Ip: ["doi_ip", "doi-ip"],
  DOI_UserAgent: [],
  TOI_Date: ["toi_date", "toi_zeipunkt"],
  TOI_Ip: ["toi_ip", "toi-ip"],
  TOI_UserAgent: [],
  isBlocked: ["isblocked"],
  blockedSince: [],
  blockedByUser: [],
  blockedReason: [],
};

const ListRowFieldAssignments = ({
  rows,
  setFieldsAssignment,
}: {
  rows: { [key: string]: string }[];
  setFieldsAssignment: (value: { [key: string]: string }) => void;
}) => {
  const prevRow = rows[0];

  const initValues: { [key: string]: string } = Object.keys(prevRow).reduce(
    (prev, curr) => {
      const auto = Object.entries(autoAssignHelper).find((i) =>
        i[1].find((i) => curr.toLowerCase().includes(i))
      );

      return { ...prev, [curr]: auto ? auto[0] : "skip" };
    },
    {}
  );

  const submitFunction = (values: { [key: string]: string }) => {
    setFieldsAssignment(values);
  };

  return (
    <div>
      <Formik initialValues={initValues} onSubmit={submitFunction}>
        {({ values, submitForm }) => {
          const fieldsToBeAssigned = listOfRequiredFieldsSavingListRow.filter(
            (elem) => !Object.values(values).includes(elem)
          );

          return (
            <Form>
              {!!fieldsToBeAssigned.length && (
                <>
                  <h3>Required fields to be assigned: </h3>
                  <ul>
                    {fieldsToBeAssigned.map((i) => (
                      <li>{i}</li>
                    ))}
                  </ul>
                </>
              )}
              <ul className={styles.list}>
                <li>
                  <h4>Contact field file import</h4>
                  <h4>File preview</h4>
                  <h4>Contact information</h4>
                </li>
                <br />
                {Object.entries(prevRow).map((elem, idx) => {
                  const selectedValues = Object.values(values).filter(
                    (val) => val && val !== values[elem[0]] && val !== "skip"
                  );

                  const availableOptions = [
                    "skip",
                    ...listOfDBFields.filter(
                      (field) =>
                        !selectedValues.includes(field) && field !== "skip"
                    ),
                  ];

                  return (
                    <li key={`field-${idx}`}>
                      <p>{elem[0]}</p>
                      <p>{String(elem[1])}</p>
                      <Field as="select" name={elem[0]}>
                        {availableOptions.map((i) => (
                          <option key={i} value={i}>
                            {i}
                          </option>
                        ))}
                      </Field>
                    </li>
                  );
                })}
              </ul>
              <button type="button" onClick={submitForm}>
                Save
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ListRowFieldAssignments;
