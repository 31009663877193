import Papa from "papaparse";

export async function parseCSVRows<T>(csvData: string): Promise<T[]> {
    console.log({csvData})
  return new Promise((resolve, reject) => {
    Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        console.log({results})
        resolve(results.data as T[]);
      },
      error: (error: any) => reject(error),
    });
  });
}
