// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tableWrapper__vgY1T {
  margin: 0;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  width: 100%;
}
.styles_tableWrapper__vgY1T:nth-child(even) {
  background-color: lightgray;
}

td, th {
  padding: 10px 30px;
  line-height: 42px;
}
td p, th p {
  margin: 0;
}

.styles_listWrapper__Ge7aS {
  position: relative;
}

.styles_wrapTable__zWLVX {
  width: 100%;
  position: absolute;
  overflow: auto;
  white-space: nowrap;
}

.styles_tableHeader__6IWJ4 {
  font-weight: 700;
  background-color: rgb(181, 181, 181) !important;
}

.styles_wrapper__U8BCq {
  padding: 20px 40px;
  font-family: "Poppins", sans-serif;
}

.styles_listHeader__6vEAR {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_buttonAndHeader__vsCqL {
  display: flex;
  gap: 20px;
  align-items: center;
}
.styles_buttonAndHeader__vsCqL h3 {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/UploadedList/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,WAAA;AAAF;AAEE;EACE,2BAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,iBAAA;AADF;AAGE;EACE,SAAA;AADJ;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,+CAAA;AAFF;;AAKA;EACE,kBAAA;EACA,kCAAA;AAFF;;AAKA;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAFF;;AAKA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAFF;AAIE;EACE,SAAA;EACA,UAAA;AAFJ","sourcesContent":[".tableWrapper {\n  margin: 0;\n  padding: 10px;\n  text-align: center;\n  white-space: nowrap;\n  align-items: center;\n\n  width: 100%;\n\n  &:nth-child(even) {\n    background-color: lightgray;\n  }\n}\n\ntd, th {\n  padding: 10px 30px;\n  line-height: 42px;\n\n  p {\n    margin: 0;\n  }\n}\n\n.listWrapper {\n  position: relative;\n}\n\n.wrapTable {\n  width: 100%;\n  position: absolute;\n  overflow: auto;\n  white-space: nowrap;\n}\n\n.tableHeader {\n  font-weight: 700;\n  background-color: rgb(181, 181, 181) !important;\n}\n\n.wrapper {\n  padding: 20px 40px;\n  font-family: 'Poppins', sans-serif;\n}\n\n.listHeader {\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.buttonAndHeader {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n\n  h3 {\n    margin: 0;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `styles_tableWrapper__vgY1T`,
	"listWrapper": `styles_listWrapper__Ge7aS`,
	"wrapTable": `styles_wrapTable__zWLVX`,
	"tableHeader": `styles_tableHeader__6IWJ4`,
	"wrapper": `styles_wrapper__U8BCq`,
	"listHeader": `styles_listHeader__6vEAR`,
	"buttonAndHeader": `styles_buttonAndHeader__vsCqL`
};
export default ___CSS_LOADER_EXPORT___;
