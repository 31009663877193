// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__SWFOF {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.styles_listWrapper__qOovA {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
  padding-bottom: 20px;
}

.styles_mainHeader__9SbRi {
  margin: 15px 0;
}

.styles_addButton__bCJHi {
  color: #fff;
  background-color: #633cd0;
  border: none;
  border-radius: 10px;
  width: 150px;
  padding: 5px;
}

.styles_fields__v8ab3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.styles_textInput__bb9HW {
  width: 300px;
}

.styles_previewTable__1fqh1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/UploadList/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.listWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 95%;\n  padding-bottom: 20px;\n}\n\n.mainHeader {\n  margin: 15px 0;\n}\n\n.addButton {\n  color: #fff;\n  background-color: #633cd0;\n  border: none;\n  border-radius: 10px;\n  width: 150px;\n  padding: 5px;\n}\n\n.fields {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.textInput {\n  width: 300px;\n}\n\n.previewTable {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__SWFOF`,
	"listWrapper": `styles_listWrapper__qOovA`,
	"mainHeader": `styles_mainHeader__9SbRi`,
	"addButton": `styles_addButton__bCJHi`,
	"fields": `styles_fields__v8ab3`,
	"textInput": `styles_textInput__bb9HW`,
	"previewTable": `styles_previewTable__1fqh1`
};
export default ___CSS_LOADER_EXPORT___;
