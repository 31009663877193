import { useQuery } from "@tanstack/react-query";
import { uploadCenterService } from "../../../services/uploadCenter";
import { QUERY_KEYS } from "../../../common/queryKeys";
import styles from "./styles.module.scss";
import ListOfLists from "./components/ListOfLists";
import { Link } from "react-router-dom";

const UploadCenter = () => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.getAllUploadedLists],
    queryFn: uploadCenterService.getAllLists,
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.listWrapper}>
        <h1 className={styles.mainHeader}>Select list</h1>
        <Link to="/uploadlist">
          <button className={styles.addButton}>+ Add new list</button>
        </Link>
        <br />
        {data?.length ? (
          <ListOfLists lists={data} />
        ) : (
          <div>No lists. Create a new one...</div>
        )}
      </div>
    </div>
  );
};

export default UploadCenter;
