// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__eI0OR {
  padding: 10px 50px;
}

.styles_listWrapper__C-8bx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  gap: 50px;
}

.styles_list__TeyvH li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/AdminSettings/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EAAA,SAAA;AACF;;AAGE;EACE,aAAA;EACA,8BAAA;EACA,cAAA;AAAJ","sourcesContent":[".wrapper {\n  padding: 10px 50px;\n}\n\n.listWrapper {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 50px;\n}\n\n.list {\n  li {\n    display: flex;\n    justify-content: space-between;\n    padding: 5px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__eI0OR`,
	"listWrapper": `styles_listWrapper__C-8bx`,
	"list": `styles_list__TeyvH`
};
export default ___CSS_LOADER_EXPORT___;
