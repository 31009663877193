import { IList, IListRow } from "../../types/UploadCenter";
import { apiService } from "./../global/index";

class UploadCenterService {
  async getAllLists() {
    return (await apiService.get("upload_list/all")) as IList[];
  }

  async getOneList(id: number) {
    return (await apiService.get(`upload_list/${id}`)) as IList;
  }

  async createList(body: { name: string; file?: IListRow[] | null }) {
    return await apiService.post("upload_list/create", body);
  }

  async deleteList(id: number) {
    return await apiService.delete(`upload_list/${id}`);
  }

  async editList(id: number, body: { name: string }) {
    return await apiService.put(`upload_list/${id}`, body);
  }

  async loadFileToTheList(id: number, body: { file: IListRow[] }) {
    return await apiService.post(`upload_list/${id}/upload`, body);
  }

  async removeListRow(listId: number, rowId: number) {
    return await apiService.delete(`upload_list/${listId}/${rowId}`);
  }

  async editListRow(listId: number, rowId: number, body: Partial<IListRow>) {
    return await apiService.put(`upload_list/${listId}/${rowId}`, body);
  }
}

export const uploadCenterService = new UploadCenterService();
