import { IconButton } from "@mui/material";
import styles from "../../styles.module.scss";
import { DeleteOutline } from "@mui/icons-material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { IListRow } from "../../../../../types/UploadCenter";
import { Field, Form, Formik } from "formik";

interface IProps {
  row: IListRow;
  mapObject: { [key: string]: string };
  deleteFunction: (id: number) => void;
  saveFunction: (values: Partial<IListRow>) => void;
}

const noEditingFields = ["email", "createdAt", "updatedAt"];

const UploadedRowEdit = ({
  row,
  mapObject,
  deleteFunction,
  saveFunction,
}: IProps) => {
  return (
    <tr className={styles.tableWrapper}>
      <Formik initialValues={row} onSubmit={saveFunction}>
        {({ handleSubmit }) => (
          <>
            {Object.keys(mapObject).map((column) => (
              <td key={`row-${row.id}-${column}`}>
                {noEditingFields.includes(column) ? (
                  <p>Not editable field</p>
                ) : (
                  <Field id={column} name={column} type="text" />
                )}
              </td>
            ))}

            <td>
              <IconButton onClick={() => handleSubmit()} aria-label="save">
                <SaveAltOutlinedIcon />
              </IconButton>
              <IconButton
                onClick={() => deleteFunction(row.id)}
                aria-label="delete"
              >
                <DeleteOutline />
              </IconButton>
            </td>
          </>
        )}
      </Formik>
    </tr>
  );
};

export default UploadedRowEdit;
