import { IList } from "../../../types/UploadCenter";

export const listTitles = ["Name", "Rows", "Created at", "Updated at", "Actions"];

export const formatListElementData = (list: IList): string[] => {
  return [
    list.name,
    String(list.rowsNumber),
    new Date(list.createdAt).toLocaleString(),
    new Date(list.updatedAt).toLocaleString(),
  ];
};
