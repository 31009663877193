import styles from "./styles.module.scss";
import { useState } from "react";
import { ICreateListValues, IListRow } from "../../../../types/UploadCenter";
import UploadedRowDisplay from "../../UploadedList/components/UploadedRowDisplay";
import UploadedRowEdit from "../../UploadedList/components/UploadedRowEdit";
import { useFormikContext } from "formik";

const mapObject = {
  email: "Email",
  firstName: "First name",
  lastName: "Last name",
  title: "Title",
  birthDate: "Birth date",
  zipCode: "Zip code",
  city: "City",
  streetAddress: "Street address",
  deliverer: "Deliverer",
  createdAt: "Created at",
  updatedAt: "Updated at",
};

const ListRowsTable = ({ rows }: { rows: IListRow[] | null }) => {
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const { setFieldValue } = useFormikContext<ICreateListValues>();

  // const deleteRow = (id: number) => {
  //   setFieldValue();
  // };

  return (
    <div className={styles.listWrapper}>
      <div className={styles.wrapTable}>
        {rows && rows.length > 0 ? (
          <table>
            <thead className={`${styles.tableWrapper} ${styles.tableHeader}`}>
              <tr>
                {Object.keys(rows[0]).map((column) => (
                  <th key={`headers-${column}`}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => {
                const isEditing = row.id === editingRowId;

                return isEditing ? (
                  <UploadedRowEdit
                    key={`edit-row-${row.id}`}
                    row={row}
                    mapObject={mapObject}
                    deleteFunction={() => {}}
                    saveFunction={() => {}}
                  />
                ) : (
                  <UploadedRowDisplay
                    key={`view-row-${row.id}`}
                    row={row}
                    mapObject={mapObject}
                    deleteFunction={() => {}}
                    editFunction={() => {}}
                    isPreview
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <div>No data saved in the list</div>
        )}
      </div>
    </div>
  );
};

export default ListRowsTable;
