import { Form, Formik } from "formik";
import Modal from "../Modal";
import DranNDropUpload from "../DragNDropUpload";
import styles from "./styles.module.scss";

interface IProps {
  toggleOpen: () => void;
  onSubmit: (file: File | null) => void;
}

const UploadFileModal = ({ toggleOpen, onSubmit }) => {
  return (
    <Modal title="Upload file" onClose={toggleOpen}>
      <Formik initialValues={{ file: null }} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <div>
              <DranNDropUpload
                name="file"
                onChangeFunction={(value) => setFieldValue("file", value)}
                value={values.file}
              />
            </div>

            <br />

            <button
              className={styles.addButton}
              type="button"
              onClick={toggleOpen}
            >
              Cancel
            </button>
            <button className={styles.addButton} type="submit">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UploadFileModal;
