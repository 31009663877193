// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__xhk8B {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.styles_listWrapper__QinM4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
  padding-bottom: 20px;
}

.styles_mainHeader__scDh1 {
  margin: 15px 0;
}

.styles_addButton__UiNI2 {
  color: #fff;
  background-color: #633cd0;
  border: none;
  border-radius: 10px;
  width: 150px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/UploadCenter/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.listWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 95%;\n  padding-bottom: 20px;\n}\n\n.mainHeader {\n  margin: 15px 0;\n}\n\n.addButton {\n  color: #fff;\n  background-color: #633cd0;\n  border: none;\n  border-radius: 10px;\n  width: 150px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__xhk8B`,
	"listWrapper": `styles_listWrapper__QinM4`,
	"mainHeader": `styles_mainHeader__scDh1`,
	"addButton": `styles_addButton__UiNI2`
};
export default ___CSS_LOADER_EXPORT___;
