// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_listOfLists__qhzDM {
  padding: 15px;
  margin: 0;
  list-style: none;
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.styles_listOfLists__qhzDM .styles_liElement__IRmNm {
  width: 100%;
}
.styles_listOfLists__qhzDM .styles_liElement__IRmNm:first-child {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid rgb(214, 213, 213);
  margin-bottom: 10px;
}
.styles_listOfLists__qhzDM .styles_liElement__IRmNm ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 4fr 4fr 3fr;
}
.styles_listOfLists__qhzDM .styles_liElement__IRmNm ul li {
  justify-content: center;
  align-items: center;
  display: flex;
}

.styles_findIcon__j84tc .styles_icon__o9qC7 {
  color: #000;
}

.styles_editIcon__cSXZt .styles_icon__o9qC7 {
  color: #633cd0;
}

.styles_deleteIcon__hOV-D .styles_icon__o9qC7 {
  color: rgb(174, 3, 3);
}`, "",{"version":3,"sources":["webpack://./src/components/OptComponents/UploadCenter/components/ListOfLists/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;EACA,kCAAA;AACF;AACE;EACE,WAAA;AACJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,2CAAA;EACA,mBAAA;AACN;AAEI;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,0CAAA;AAAN;AAEM;EACE,uBAAA;EACA,mBAAA;EACA,aAAA;AAAR;;AAOE;EACE,WAAA;AAJJ;;AASE;EACE,cAAA;AANJ;;AAWE;EACE,qBAAA;AARJ","sourcesContent":[".listOfLists {\n  padding: 15px;\n  margin: 0;\n  list-style: none;\n  width: 100%;\n  font-family: 'Poppins', sans-serif;\n\n  .liElement {\n    width: 100%;\n\n    &:first-child {\n      font-size: 18px;\n      font-weight: 700;\n      border-bottom: 1px solid rgb(214, 213, 213);\n      margin-bottom: 10px;\n    }\n\n    ul {\n      list-style: none;\n      padding: 0;\n      margin: 0;\n      display: grid;\n      width: 100%;\n      grid-template-columns: 4fr 2fr 4fr 4fr 3fr;\n\n      li {\n        justify-content: center;\n        align-items: center;\n        display: flex;\n      }\n    }\n  }\n}\n\n.findIcon {\n  .icon {\n    color: #000;\n  }\n}\n\n.editIcon {\n  .icon {\n    color: #633cd0;\n  }\n}\n\n.deleteIcon {\n  .icon {\n    color: rgb(174, 3, 3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listOfLists": `styles_listOfLists__qhzDM`,
	"liElement": `styles_liElement__IRmNm`,
	"findIcon": `styles_findIcon__j84tc`,
	"icon": `styles_icon__o9qC7`,
	"editIcon": `styles_editIcon__cSXZt`,
	"deleteIcon": `styles_deleteIcon__hOV-D`
};
export default ___CSS_LOADER_EXPORT___;
